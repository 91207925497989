import { useSpring, animated } from 'react-spring'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { resolveTileLink } from '/machinery/link'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'
import { Tile } from '/features/buildingBlocks/tile/Tile'
import { ShowRewardCalculatorPortalFlowButton } from '/features/buildingBlocks/reward-calculator/ShowRewardCalculatorPortalFlowButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './RelatedContentGrid.css'

export function RelatedContentGridArticle({ tiles, availableStyleContexts = undefined }) {
  return (
    <RelatedContentGridBase className={styles.componentArticle} {... { tiles, availableStyleContexts }} />
  )
}

export function RelatedContentGridHome({ tiles, availableStyleContexts }) {
  return (
    <RelatedContentGridBase {... { tiles, availableStyleContexts }} />
  )
}

export function RelatedContentGridHero({ tiles, availableStyleContexts = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const selectedTiles = isViewportMd ? tiles : tiles.slice(0, 3)

  return <RelatedContentGridBase className={styles.componentHero} tiles={selectedTiles} {...{ availableStyleContexts }} />
}

function RelatedContentGridBase({ tiles, availableStyleContexts, className = undefined }) {
  const language = useLanguage()

  return (
    <span className={styles.componentBase}>
      <ul className={cx(styles.list, className)}>
        {tiles?.filter(x => x.representation).map((x, i) => (
          <li data-context-tile-index={i + 1} key={[i, x._id].join()}>
            <Tile
              representation={x.representation}
              href={resolveTileLink({ tile: x, language })}
              {...{ availableStyleContexts }}
            />
          </li>
        ))}
      </ul>
    </span>
  )
}

export function RelatedContentGridFooter({ tiles, benefitPageLinkRef }) {
  const { __ } = useTranslate()
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm)

  const [firstContentTile, ...restOfContentTiles] = tiles.slice(0,
    isViewportLg ? 13 :
    isViewportSm ? 9 :
    7
  )

  return (
    <ul className={styles.componentFooter}>
      <GridFooterTile
        id={0}
        gridArea='amp'
        layoutClassName={styles.gridFooterSkillsMatchButtonLayout}
        renderComponent={() =>
          <div className={styles.skillsMatchButton}>
            <ShowSkillsMatchPortalFlowButton
              label={__`my-skills`}
              buttonType='block'
              layoutClassName={styles.skillsMatchButtonLayout}
              dataX='link-to-salary-grid'
            />
          </div>
        }
      />

      {/*Remove the first item from the array and place it to the second place in the grid to ensure the correct tabIndex. */}
      <GridFooterTile id={1} tile={firstContentTile} />

      <GridFooterTile
        id={2}
        gridArea='amp-2'
        layoutClassName={styles.gridFooterCalculatorButtonLayout}
        renderComponent={() =>
          <ShowRewardCalculatorPortalFlowButton
            buttonType='tile'
            linkRef={benefitPageLinkRef}
            dataX='open-calculator'
            label={__`salary-checker`}
            layoutClassName={styles.rewardCalculatorButtonLayout}
          />
        }
      />

      {restOfContentTiles.filter(x => x.representation).map((tile, i) => (
        <GridFooterTile key={i} id={3 + i} {...{ tile }} />
      ))}
    </ul>
  )
}

function GridFooterTile({ id, layoutClassName = undefined, gridArea = undefined, renderComponent = undefined, tile = undefined }) {
  const language = useLanguage()
  const { ref, wasInViewport } = useWasInViewport({ rootMargin: '-10%' })
  const { representation } = tile || {}
  const area = gridArea ? gridArea : `tile-${id}`

  const style = useSpring({
    delay: 25 * id,
    opacity: wasInViewport ? 1 : 0,
    scale: wasInViewport ? 1 : 0.4,
    config: { mass: 0.5, tension: 600, friction: 50, precision: 0.01, velocity: 0 },
  })

  return (
    <animated.li
      data-context-tile-index={id}
      style={{ gridArea: area, ...style }}
      className={cx(styles.componentGridFooterTile, layoutClassName)}
      {...{ ref }}
    >
      {tile
        ? <Tile href={resolveTileLink({ tile, language })} {...{ representation }} />
        : renderComponent()
      }
    </animated.li>
  )
}
